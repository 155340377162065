<template>
  <!-- Navs -->
  <div class="flex flex-row items-center gap-x-2">
    <router-link to="/" class="font-EffraR text-base" style="color: #515151">
      {{ $t("patient.home") }}
    </router-link>
    <div class="hidden lg:flex items-center gap-x-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="#515151"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7.5 15l5-5-5-5"
        />
      </svg>

      <router-link
        :to="currectUrl"
        class="font-EffraR text-base"
        style="color: #515151"
        >{{
          user.landingPage &&
          user.landingPage.address &&
          user.landingPage.address.city
            ? user.landingPage.address.city.name
            : "--"
        }}</router-link
      >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="#515151"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7.5 15l5-5-5-5"
        />
      </svg>
      <router-link
        :to="currectUrl"
        class="font-EffraR text-base"
        style="color: #515151"
      >
        {{
          user.landingPage &&
          user.landingPage.specialities &&
          user.landingPage.specialities.length
            ? user.landingPage.specialities[0].name
            : "--"
        }}</router-link
      >
    </div>
    <div class="flex lg:hidden items-center gap-x-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="#515151"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7.5 15l5-5-5-5"
        />
      </svg>
      <router-link :to="currectUrl">...</router-link>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#515151"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M7.5 15l5-5-5-5"
      />
    </svg>
    <span class="font-EffraM text-base" :style="`color: #8c323a;`">{{
      `${user.title}. ${user.firstName} ${user.lastName}`
    }}</span>
  </div>
</template>

<script>
import { get, kebabCase } from "lodash";

export default {
  props: {
    user: { type: Object, required: true },
  },
  computed: {
    currectUrl() {
      const speciality = kebabCase(
        get(
          this.user,
          "landingPage.specialities[0].name",
          "medecin-generaliste"
        )
      ).toLowerCase();
      const city = kebabCase(
        get(this.user, "landingPage.address.city.name", "maroc")
      ).toLowerCase();
      return this.$router.resolve({
        name: "doctorsList",
        params: {
          city: city,
          query: speciality,
        },
      }).href;
    },
  },
};
</script>

<style></style>
